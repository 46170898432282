import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Select, Form, Input, DatePicker, Upload } from 'antd';
import dayjs from 'dayjs';
import { InboxOutlined } from '@ant-design/icons';

import Breadcrumb from 'src/components/Breadcrumb';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import { ComplaintIssueTxt, Router } from 'src/constants';
import ComplaintCreateVM, { MAX_FILE_COUNT } from './viewModel';
import ComplaintGuidelinesModal from './ComplaintGuidelinesModal';
import { FileCard } from './Upload';

import styles from './styles.module.scss';

@observer
class ComplaintCreatePage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ComplaintCreateVM(this.props.form, this.props.router);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  renderReporteeSection() {
    return (
      <section className={styles.section}>
        <h2
          className={styles.sectionTitle}
        >
          第一部分：提報對象資料
        </h2>
        <Form
          form={this.vm.form}
          layout="vertical"
        >
          <Form.Item
            label="姓名"
            name="targetName"
            rules={[{ required: true, message: '姓名必填' }]}
          >
            <Input placeholder="請輸入提報對象姓名" />
          </Form.Item>
          <Form.Item
            label={(
              <div>
                <div>身份證字號/護照號碼（外籍）</div>
                <div className={styles.extra}>為判斷被舉報對象的依據，請謹慎填寫正確資料，若未填寫可能無法比對正確名單</div>
              </div>
            )}
            name="targetIdCard"
            validateTrigger="onBlur"
            rules={[
              { validator: (_, value) => this.vm.validateIdNumber(_, value) }
            ]}
          >
            <Input placeholder="請輸入提報對象姓名身份證字號/護照號碼" />
          </Form.Item>
          <Form.Item
            label={(
              <div>
                <div>手機號碼</div>
                <div className={styles.extra}>為判斷被舉報對象的依據，請謹慎填寫正確資料，若未填寫可能無法比對正確名單</div>
              </div>
            )}
            name="targetPhone"
            validateTrigger="onBlur"
            rules={[
              { validator: (_, value) => this.vm.validatePhone(_, value, true) }
            ]}
          >
            <Input placeholder="請輸入提報對象手機號碼" />
          </Form.Item>
          <Form.Item
            label={(
              <div>
                <div>電子郵件</div>
                <div className={styles.extra}>為判斷被舉報對象的依據，請謹慎填寫正確資料，若未填寫可能無法比對正確名單</div>
              </div>
            )}
            name="email"
            validateTrigger="onBlur"
            rules={[
              { validator: (_, value) => this.vm.validateEmail(_, value) }
            ]}
          >
            <Input placeholder="請輸入提報對象姓名電子郵件" />
          </Form.Item>
          <Form.Item
            label={(
              <div>
                <div>居住地址</div>
                <div className={styles.extra}>為判斷被舉報對象的依據，請謹慎填寫正確資料，若未填寫可能無法比對正確名單</div>
              </div>
            )}
            name="address"
          >
            <Input placeholder="請輸入提報對象姓名居住地址" />
          </Form.Item>
        </Form>
      </section>
    );
  }

  renderContentSection() {
    return (
      <section className={styles.section}>
        <h2
          className={styles.sectionTitle}
        >
          第二部分：事件內容
        </h2>
        <Form
          form={this.vm.form}
          layout="vertical"
        >
          <Form.Item
            label="發生時間"
            name="eventDate"
            rules={[{ required: true, message: '發生時間必填' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              placeholder="選擇日期"
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day');
              }}
            />
          </Form.Item>
          <Form.Item
            label="發生地區"
            name="eventLocation"
            rules={[{ required: true, message: '發生地區必填' }]}
          >
            <Input
              placeholder="ex. 台北市、Facebook粉絲專頁、XXX中途之家"
            />
          </Form.Item>
          <Form.Item
            label="主要問題"
            name="eventIssues"
            rules={[{ required: true, message: '主要問題必填' }]}
          >
            <Select
              placeholder="請選擇"
              options={
                Object.entries(ComplaintIssueTxt).map(([key, label]) => (
                  { value: key, label }
                ))
              }
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            label="事件描述"
            name="eventDescription"
            rules={[{ required: true, message: '事件描述必填' }]}
          >
            <Input.TextArea
              placeholder="請盡量清楚描述事件發生的過程，以及提供任何有利於查證此案件的管道或資料（ex. 社群連結、XXX認養平台、社團法人台灣防止虐待動物協會 案件編號XXXX）"
              maxLength={2000}
              rows={5}
              showCount
            />
          </Form.Item>

          <div className={styles.fieldGap}>事件舉證影像/檔案（上限10個檔案，.jpg, .png, .pdf, .mp3, .mp4）</div>
          {this.vm.isMounted && (
            <div style={{ height: 200 }}>
              <Upload.Dragger
                customRequest={this.vm.onFileSelected}
                accept="image/png,image/jpg,image/jpeg,.pdf,.mp3,.mp4"
                showUploadList={false}
                multiple
                maxCount={10}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">點擊此處或將檔案拖放至此</p>
              </Upload.Dragger>
            </div>
          )}
          {
            this.vm.fileList.length ? (
              <div className={styles.fileList}>
                {
                  this.vm.fileList.map((item, idx) => (
                    <FileCard
                      key={`file-${item.key || item.name}`}
                      item={item}
                      isLengthLimitReached={(idx + 1) > MAX_FILE_COUNT}
                      onClear={this.vm.onFileRemove}
                    />
                  ))
                }
              </div>
            ) : null
          }
          <div className={styles.formGap} />
        </Form>
      </section>
    );
  }

  renderContactSection() {
    return (
      <section className={styles.section}>
        <h2
          className={styles.sectionTitle}
        >
          第三部分：您的聯絡資訊
        </h2>
        <Form
          form={this.vm.form}
          layout="vertical"
        >
          <Form.Item
            label="聯絡電話"
            name="contactPhone"
            rules={[{
              required: true,
              message: '請填入有效的聯絡電話',
              validator: (_, value) => this.vm.validatePhone(_, value, false)
            }]}
            className="requiredFormItemWithExtraNote"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="email"
            name="contactEmail"
            rules={[{ required: true, message: 'email 必填' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </section>
    );
  }

  renderGuidelinesModal() {
    return (
      <ComplaintGuidelinesModal
        open={this.vm.isGuidelinesModalOpen}
        onClose={this.vm.closeGuidelinesModal}
      />
    );
  }

  render() {
    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '舉報黑名單', link: Router.Client.Complaint },
            { title: '舉報' }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>
            {this.renderReporteeSection()}
            {this.renderContentSection()}
            {this.renderContactSection()}

            <div
              className={styles.submitButtonWrapper}
            >
              <div className={styles.col}>
                <Button
                  className={styles.guidelinesButton}
                  onClick={this.vm.openGuidelinesModal}
                  type="link"
                >
                  舉報黑名單說明與注意事項
                </Button>
                <Button
                  className={styles.submitButton}
                  onClick={this.vm.onClickSubmit}
                  loading={this.vm.isLoading}
                >
                  提交
                </Button>
              </div>
            </div>
          </div>
        </div>

        {this.renderGuidelinesModal()}
      </>
    );
  }
}

ComplaintCreatePage.propTypes = {
  form: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};

const ProfilePageHOC = (props) => {
  const [form] = Form.useForm();

  return (
    <ComplaintCreatePage
      form={form}
      {...props}
    />
  );
};



export default withRouter(withProfile(ProfilePageHOC, true));
